<template>
    <el-main>
        <el-button type="primary" size="small" class="add_goods" @click="dialogVisible = true">
            + 添加做法
        </el-button>
        <el-button size="small" class="add_goods" @click="dialogVisible2 = true">
            + 新增分类
        </el-button>
        <el-button size="small" class="add_goods" @click="dialogVisible3 = true">
            编辑分类
        </el-button>
        <el-form class="el-form-search" label-width="90px">
            <el-form-item label="做法名称：">
                <el-input v-model="dataForm.practice_name" size="small" placeholder="请输入不辣/中辣/重辣等"></el-input>
            </el-form-item>
            <el-form-item label=" " label-width="15px">
                <el-button type="primary" size="small" @click="get_data_list(1)">搜索</el-button>
                <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="data_list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
            <template slot="empty">
                <No />
            </template>
            <el-table-column label="做法名称" align="center" prop="practice_name"></el-table-column>
            <el-table-column label="做法分类" align="center" prop="practice_classify_name"></el-table-column>
            <el-table-column label="加价金额" align="center" prop="title">
                <template v-slot="{ row }">￥{{ row.price }}</template>
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template v-slot="{ row }">
                    <el-button type="text" @click="handleEdit(row)">编辑</el-button>
                    <el-button type="text" @click="deletePratice(row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <Paging :total="total_number" :page="dataForm.page" :pageNum="dataForm.rows" @updatePageNum="updateData"></Paging>

        <el-dialog :title="form.id ? '编辑做法' : '添加做法'" :visible.sync="dialogVisible" width="40%" @closed="handleClose">
            <el-form label-width="100px" :rules="rules" ref="form" :model="form">
                <el-form-item label="做法名称：" prop="practice_name">
                    <el-input v-model="form.practice_name" size="small"></el-input>
                </el-form-item>
                <el-form-item label="做法分类：" prop="practice_classify_id">
                    <el-select v-model="form.practice_classify_id" size="small" placeholder="请选择" style="width: 80%;">
                        <el-option :label="item.practice_classify_name" :value="item.id"
                            v-for="item in practiceClassifyLists" :key="item.id"></el-option>
                    </el-select>
                    <el-button type="text" @click="dialogVisible2 = true" style="margin-left:10px;">添加分类</el-button>
                </el-form-item>
                <el-form-item label="加价金额：" prop="price">
                    <el-input v-model="form.price" size="small"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="confirmPractice">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="新增分类" :visible.sync="dialogVisible2" width="40%">
            <el-form label-width="120px" :rules="classifyRules" ref="classifyForm" :model="classifyForm">
                <el-form-item label="分类名称：" prop="practice_classify_name">
                    <el-input v-model="classifyForm.practice_classify_name" size="small"></el-input>
                </el-form-item>
                <el-form-item label="是否支持多选：">
                    <el-switch v-model="classifyForm.is_multiple_choice" :active-value="1" :inactive-value="-1"></el-switch>
                    <div class="tips">如开启，例如奶茶添加小料，可以选择多种小料</div>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible2 = false">取 消</el-button>
                <el-button type="primary" @click="goodsPracticeClassifyAdd">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="编辑分类" :visible.sync="dialogVisible3" width="40%">
            <el-table :data="practiceClassifyList" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
                <template slot="empty">
                    <No />
                </template>
                <el-table-column label="分类名称" align="center" prop="title">
                    <template v-slot="{ row }">
                        <el-input v-model="row.practice_classify_name" size="small" :disabled="!row.is_edit"></el-input>
                    </template>
                </el-table-column>
                <el-table-column label="是否支持多选" align="center" prop="title">
                    <template v-slot="{ row }">
                        <el-switch v-model="row.is_multiple_choice" :active-value="1" :inactive-value="-1"
                            :disabled="!row.is_edit"></el-switch>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center">
                    <template v-slot="{ row }">
                        <el-button type="text" v-if="row.is_edit" @click="editClassify(row)">确定</el-button>
                        <el-button type="text" v-else @click="row.is_edit = true">编辑</el-button>
                        <el-button type="text" @click="deleteClassify(row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <Paging :total="classify_total_number" :page="practiceClassifyForm.page" :pageNum="practiceClassifyForm.rows"
                @updatePageNum="updateData2">
            </Paging>
        </el-dialog>
    </el-main>
</template>
  
<script>
import { getDateformat } from '@/util/getDate.js';
import Paging from '@/components/paging';
import { inspectPrice } from '@/util/verification.js';
import _ from 'lodash';
export default {
    components: {
        Paging,
    },
    created () {
        this.getGoodsPracticeClassifyLists()
        this.get_data_list();
        this.getGoodsPracticeClassifyList()
    },
    data () {
        return {
            getDateformat,
            // 列表查询条件
            dataForm: {
                page: 1,
                rows: 10,
                practice_name: ''
            },

            data_list: [],
            total_number: 0,
            dialogVisible: false,
            practiceClassifyLists: [],
            form: {
                practice_classify_id: '',
                practice_name: '',
                price: ''
            },
            rules: {
                practice_name: [{ required: true, message: "请填写做法名称", trigger: "blur" }],
                practice_classify_id: [{ required: true, message: "请选择做法分类", trigger: "change" }],
                price: [{ required: true, message: "请填写价格", trigger: "blur" }, {
                    validator: (rule, value, callback) => {
                        if (!inspectPrice(value)) {
                            callback(new Error("请填写正确价格格式"));
                        } else {
                            callback();
                        }
                    }, trigger: "blur"
                }]
            },
            dialogVisible2: false,
            classifyForm: {
                practice_classify_name: '',
                is_multiple_choice: -1
            },
            classifyRules: {
                practice_classify_name: [{ required: true, message: "请填写分类名称", trigger: "blur" }],
            },
            dialogVisible3: false,
            practiceClassifyForm: {
                page: 1,
                rows: 10,
            },
            practiceClassifyList: [],
            classify_total_number: 0
        };
    },
    methods: {
        updateData (val, status) {
            if (status == 0) {
                this.dataForm.rows = val;
            } else {
                this.dataForm.page = val;
            }
            this.get_data_list();
        },
        updateData2 (val, status) {
            if (status == 0) {
                this.practiceClassifyForm.rows = val;
            } else {
                this.practiceClassifyForm.page = val;
            }
            this.getGoodsPracticeClassifyList();
        },
        clearSearch () {
            this.dataForm = {
                page: 1,
                rows: 10,
                practice_name: ''
            }
            this.get_data_list(1);
        },
        get_data_list (style) {
            if (style) this.dataForm.page = 1;
            this.$axios.post(this.$api.catering.GoodsPracticeList, this.dataForm).then(res => {
                if (res.code === 0) {
                    this.data_list = res.result.list;
                    this.total_number = res.result.total_number;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        getGoodsPracticeClassifyLists () {
            this.$axios.post(this.$api.catering.GoodsPracticeClassifyLists).then(res => {
                if (res.code === 0) {
                    this.practiceClassifyLists = res.result
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        getGoodsPracticeClassifyList (style) {
            if (style) this.practiceClassifyForm.page = 1;
            this.$axios.post(this.$api.catering.GoodsPracticeClassifyList, this.practiceClassifyForm).then(res => {
                if (res.code === 0) {
                    let { list, total_number } = res.result
                    list.forEach(item => {
                        item.is_edit = false
                    })
                    this.practiceClassifyList = list
                    this.classify_total_number = total_number
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        goodsPracticeClassifyAdd () {
            this.$refs.classifyForm.validate((valid) => {
                if (valid) {
                    this.$axios.post(this.$api.catering.GoodsPracticeClassifyAdd, this.classifyForm).then(res => {
                        if (res.code === 0) {
                            this.$message.success(`添加成功`);
                            this.getGoodsPracticeClassifyLists()
                            this.getGoodsPracticeClassifyList()
                            this.dialogVisible2 = false
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
                }
            })

        },
        handleEdit (row) {
            this.form = {
                practice_classify_id: row.practice_classify_id,
                practice_name: row.practice_name,
                price: row.price,
                id: row.id
            }
            this.dialogVisible = true
        },
        handleClose () {
            this.form = {
                practice_classify_id: '',
                practice_name: '',
                price: ''
            }
            this.$refs.form.clearValidate()
        },
        deletePratice (data) {
            this.$confirm('此操作将删除该做法, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    return this.$axios.post(this.$api.catering.GoodsPracticeDel, {
                        id: data.id,
                    });
                })
                .then(res => {
                    if (res.code == 0) {
                        this.$message.success(`删除成功`);
                        this.get_data_list(1);
                    } else {
                        this.$message.error(res.msg);
                    }
                })
                .catch(() => { });
        },
        confirmPractice () {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    let obj = { ...this.form }
                    let url = obj.id ? this.$api.catering.GoodsPracticeEdit : this.$api.catering.GoodsPracticeAdd
                    this.$axios.post(url, obj).then(res => {
                        if (res.code === 0) {
                            this.$message.success(obj.id ? '编辑成功' : `添加成功`);
                            this.dialogVisible = false
                            this.get_data_list(1);
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
                }
            })
        },
        deleteClassify (data) {
            this.$confirm('此操作将删除该做法分类, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    return this.$axios.post(this.$api.catering.GoodsPracticeClassifyDel, {
                        id: data.id,
                    });
                })
                .then(res => {
                    if (res.code == 0) {
                        this.$message.success(`删除成功`);
                        this.getGoodsPracticeClassifyList(1);
                    } else {
                        this.$message.error(res.msg);
                    }
                })
                .catch(() => { });
        },
        editClassify (row) {
            this.$axios.post(this.$api.catering.GoodsPracticeClassifyEdit, {
                id: row.id,
                practice_classify_name: row.practice_classify_name,
                is_multiple_choice: row.is_multiple_choice
            }).then(res => {
                if (res.code === 0) {
                    this.$message.success('编辑成功');
                    row.is_edit = false
                } else {
                    this.$message.error(res.msg);
                }
            });
        }
    },
};
</script>
  
<style lang="less" scoped>
.el-main {
    min-height: 100%;
    background-color: #fff;

    .add_goods {
        margin: 16px 10px 16px 0;
    }

    .tips {
        color: #999;
    }
}
</style>